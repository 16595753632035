import { Elm } from "./Main.elm";

const app = Elm.Main.init({
  node: document.querySelector("main"),
  flags: {
    pixelsPerDay: localStorage["savePixelsPerDay"]
      ? parseInt(localStorage["savePixelsPerDay"], 10)
      : 8
  }
});

app.ports.savePixelsPerDay.subscribe(value => {
  localStorage["savePixelsPerDay"] = value;

  app.ports.onSavedPixelsPerDay.send(value);
});

app.ports.globalCursor.subscribe(value => {
  document.body.style.cursor = value;
});

const isArrowKey = e => {
  return [37, 38, 39, 40].includes(e.keyCode);
};

let isShiftDown = false;
let isCmdDown = false;
document.addEventListener("keydown", e => {
  if (isShiftDown && isArrowKey(e)) {
    e.preventDefault();
  }

  if (e.keyCode === 16) {
    isShiftDown = true;
  }

  if (e.keyCode === 91) {
    isCmdDown = true;
  }

  if (isCmdDown) {
    if (e.keyCode === 65) {
      e.preventDefault();
      app.ports.onSelectAll.send(null);
    }
  }

  app.ports.onKeyDown.send(e.keyCode);
});

document.addEventListener("keyup", e => {
  if (isShiftDown && isArrowKey(e)) {
    e.preventDefault();
  }

  if (e.keyCode === 16) {
    isShiftDown = false;
  }

  if (e.keyCode === 91) {
    isCmdDown = false;
  }

  app.ports.onKeyUp.send(e.keyCode);
});

document.body.addEventListener("click", e => {
  if (!e.defaultPrevented) {
    if (!e.path.includes(document.querySelector(".header-with-overflow"))) {
      app.ports.onDeselectAll.send(null);
    }
  }
});
